import {Administration} from 'app/documents/administrations';
import {DocumentData} from '../../documents/document-data';
import {Dictionary} from '../../utils/typedefs';
import {UUID} from '../../utils/uuid';
import {ClauseLinkRequired} from '../fragment-link/clause-link-required';
import {TableCellFragment, TableFragment, TableRowFragment} from '../table/table-fragment';
import {
  AnchorFragment,
  ClauseFragment,
  ClauseReferenceTargetType,
  ClauseType,
  DocumentFragment,
  DocumentInformationFragment,
  DocumentInformationType,
  DocumentReferenceFragment,
  EquationFragment,
  FigureFragment,
  Fragment,
  FragmentType,
  InlineReferenceFragment,
  InternalReferenceType,
  ListFragment,
  ListItemFragment,
  MemoFragment,
  ReferenceType,
  RootFragment,
  SectionFragment,
  SectionType,
  SubscriptFragment,
  SuperscriptFragment,
  TextFragment,
} from '../types';
import {AssociatedDocumentInformationFragment} from '../types/associated-document-information-fragment';
import {AssociatedDocumentType} from '../types/associated-document-information-type';
import {ClauseGroupFragment} from '../types/clause-group-fragment';
import {ClauseGroupType} from '../types/clause-group-type';
import {InputFragment} from '../types/input/input-fragment';
import {ReferenceInputDisplayType, ReferenceInputFragment} from '../types/input/reference-input-fragment';
import {RequiredReferenceCount} from '../types/input/required-reference-count';
import {UnitInputFragment} from '../types/input/unit-input-fragment';
import {ReadonlyFragment} from '../types/readonly-fragment';
import {InternalDocumentReferenceFragment} from '../types/reference/internal-document-reference-fragment';
import {InternalInlineReferenceFragment} from '../types/reference/internal-inline-reference-fragment';
import {TargetDocumentType} from '../types/reference/target-document-type';
import {SectionGroupFragment} from '../types/section-group-fragment';
import {SectionGroupType} from '../types/section-group-type';
import {SpecifierInstructionType} from '../types/specifier-instruction-type';
import {StandardFormatType} from '../types/standard-format-type';

export class FragmentMapper {
  /**
   * Deserialise a JSON object to the appropriate class deriving from Fragment.  This
   * lookup is based on the 'type' property.  If the type is unknown, an Error is thrown.
   *
   * @param json {any}        The JSON to deserialise
   * @returns    {Fragment}   The deserialised class
   * @throws     {Error}      If type is unknown
   */
  public static deserialise(json: any): Fragment {
    let result: Fragment = null;

    // Check we've not been given stringified JSON
    json = typeof json === 'object' ? json : JSON.parse(json);

    // Allow JSON objects to have either the number or string representation of a FragmentType
    const type: FragmentType = typeof json.type === 'number' ? json.type : FragmentType[json.type];

    const id: UUID = UUID.orThrow(json.id);
    const children: Fragment[] = deserialiseArray(json.children instanceof Array ? json.children : []);

    switch (type) {
      case FragmentType.TEXT:
        {
          result = new TextFragment(id, valueFrom(json), FragmentType.TEXT);
        }
        break;

      case FragmentType.SUPERSCRIPT:
        {
          result = new SuperscriptFragment(id, valueFrom(json));
        }
        break;

      case FragmentType.SUBSCRIPT:
        {
          result = new SubscriptFragment(id, valueFrom(json));
        }
        break;

      case FragmentType.MEMO:
        {
          result = new MemoFragment(id, valueFrom(json));
        }
        break;

      case FragmentType.LIST:
        {
          result = new ListFragment(
            id,
            children as ListItemFragment[],
            json.ordered === true,
            json.listStartIndex,
            json.primaryListIndexingType,
            json.secondaryListIndexingType
          );
        }
        break;

      case FragmentType.LIST_ITEM:
        {
          result = new ListItemFragment(id, children as ListItemFragment[], json.indented);
        }
        break;

      case FragmentType.EQUATION:
        {
          result = new EquationFragment(id, json.source, json.inline, json.caption);
          result.children.push(...children);
        }
        break;

      case FragmentType.FIGURE:
        {
          result = new FigureFragment(
            id,
            UUID.orNull(json.uploadId),
            json.caption,
            json.landscape,
            json.altText,
            json.virusScanState,
            json.uploadProperties,
            json.isIllustrativeFigure
          );
        }
        break;

      case FragmentType.TABLE:
        {
          result = new TableFragment(
            id,
            children as TableRowFragment[],
            json.caption,
            json.landscape,
            json.columnWidths,
            json.hasHeaderRow
          );
        }
        break;

      case FragmentType.TABLE_ROW:
        {
          result = new TableRowFragment(id, children as TableCellFragment[]);
        }
        break;

      case FragmentType.TABLE_CELL:
        {
          result = new TableCellFragment(
            id,
            children,
            json.rowSpan,
            json.colSpan,
            json.deleted,
            json.bold,
            json.alignment,
            json.headerRowBorder
          );
        }
        break;

      case FragmentType.CLAUSE:
        {
          const clauseType: ClauseType = ClauseType[json.clauseType as string];
          const standardFormatType: StandardFormatType = StandardFormatType[json.standardFormatType as string];
          const administration: Administration = Administration[json.administration as string];
          const specifierInstructionType: SpecifierInstructionType =
            SpecifierInstructionType[json.specifierInstructionType as string];
          const verificationLinkRequired: ClauseLinkRequired =
            ClauseLinkRequired[json.verificationLinkRequired as string];
          const documentationLinkRequired: ClauseLinkRequired =
            ClauseLinkRequired[json.documentationLinkRequired as string];
          result = new ClauseFragment(
            id,
            clauseType,
            children,
            json.value,
            standardFormatType,
            administration,
            specifierInstructionType,
            verificationLinkRequired,
            documentationLinkRequired,
            json.isUnmodifiableClause
          );
        }
        break;

      case FragmentType.SECTION:
        {
          const sectionType: SectionType = SectionType[json.sectionType as string];
          const administration: Administration = Administration[json.administration as string];
          result = new SectionFragment(
            id,
            json.value,
            sectionType,
            children as ClauseFragment[],
            json.deleted,
            json.subject,
            json.topic,
            json.wsrCode,
            administration
          );
        }
        break;

      case FragmentType.DOCUMENT:
        {
          const data: DocumentData = DocumentData.deserialise(json.documentData);
          result = new DocumentFragment(id, children as SectionFragment[], json.suite, data, json.deleted);
        }
        break;

      case FragmentType.ROOT:
        {
          result = new RootFragment(children as DocumentFragment[]);
        }
        break;

      case FragmentType.DOCUMENT_REFERENCE:
        {
          const referenceType: ReferenceType = ReferenceType[json.referenceType as string];
          result = new DocumentReferenceFragment(id, referenceType, UUID.orThrow(json.globalReference), json.value);
        }
        break;

      case FragmentType.INLINE_REFERENCE:
        {
          result = new InlineReferenceFragment(id, UUID.orThrow(json.documentReference), json.deleted);
        }
        break;

      case FragmentType.INTERNAL_DOCUMENT_REFERENCE: {
        const referenceType: ReferenceType = ReferenceType[json.referenceType];
        const targetDocumentType: TargetDocumentType = TargetDocumentType[json.targetDocumentType];
        const internalReferenceType: InternalReferenceType = InternalReferenceType[json.internalReferenceType];
        const clauseReferenceTargetType: ClauseReferenceTargetType =
          ClauseReferenceTargetType[json.clauseReferenceTargetType];
        const targetClauseType: ClauseType = ClauseType[json?.targetClauseType] || null;
        const targetDocumentId: UUID = UUID.orThrow(json.targetDocumentId);
        const targetSectionId: UUID = UUID.orNull(json.targetSectionId);
        const targetVersionId: UUID = UUID.orNull(json.targetVersionId);
        const targetFragmentId: UUID = UUID.orNull(json.targetFragmentId);

        result = new InternalDocumentReferenceFragment(
          id,
          referenceType,
          targetDocumentType,
          internalReferenceType,
          clauseReferenceTargetType,
          targetDocumentId,
          targetSectionId,
          targetVersionId,
          targetFragmentId,
          json.documentCode,
          json.documentTitle,
          json.sectionTitle,
          json.sectionIndex,
          json.targetFragmentDeleted,
          json.targetFragmentIndex,
          json.targetFragmentValue,
          targetClauseType,
          json.subject,
          json.topic,
          json.wsrCode,
          json.targetFragmentOrderingWeight
        );
        break;
      }

      case FragmentType.INTERNAL_INLINE_REFERENCE: {
        const internalDocumentReferenceId: UUID = UUID.orThrow(json.internalDocumentReferenceId);
        result = new InternalInlineReferenceFragment(id, internalDocumentReferenceId, json.deleted, json.readonly);
        break;
      }

      case FragmentType.ANCHOR: {
        result = new AnchorFragment(id, json.hasBeenResolved, json.isFirstAnchor);
        (result as AnchorFragment).otherAnchorId = UUID.orNull(json.otherAnchorId);
        break;
      }

      case FragmentType.DOCUMENT_INFORMATION: {
        result = new DocumentInformationFragment(
          id,
          json.value,
          json.documentInformationType as DocumentInformationType,
          json.values
        );
        result.children.push(...children);
        break;
      }

      case FragmentType.ASSOCIATED_DOCUMENT_INFORMATION: {
        result = new AssociatedDocumentInformationFragment(
          id,
          AssociatedDocumentType[json.associatedDocumentType as string],
          json.filename,
          json.reference
        );
        break;
      }

      case FragmentType.CLAUSE_GROUP: {
        const clauseGroupType: ClauseGroupType = ClauseGroupType[json.clauseGroupType as string];
        const standardFormatType: StandardFormatType = StandardFormatType[json.standardFormatType as string];
        const administration: Administration = Administration[json.administration as string];
        result = new ClauseGroupFragment(id, children, clauseGroupType, standardFormatType, administration);
        break;
      }

      case FragmentType.READONLY: {
        result = new ReadonlyFragment(id, valueFrom(json));
        break;
      }

      case FragmentType.INPUT: {
        result = new InputFragment(id, json.placeholderValue, children);
        break;
      }

      case FragmentType.REFERENCE_INPUT: {
        const requiredReferenceCount: RequiredReferenceCount = RequiredReferenceCount[json.requiredReferenceCount];
        const internalReferenceType: InternalReferenceType = InternalReferenceType[json.internalReferenceType];
        const referenceInputDisplayType: ReferenceInputDisplayType =
          internalReferenceType === InternalReferenceType.CLAUSE_REFERENCE
            ? ReferenceInputDisplayType.CLAUSE_TEMPLATE
            : ReferenceInputDisplayType.SECTION_TEMPLATE;
        result = new ReferenceInputFragment(
          id,
          json.placeholderValue,
          requiredReferenceCount,
          internalReferenceType,
          children,
          referenceInputDisplayType
        );
        break;
      }

      case FragmentType.UNIT_INPUT: {
        const unitId: UUID = UUID.orNull(json.unitId);
        result = new UnitInputFragment(id, json.placeholderValue, unitId, json.unitEquationSource);
        break;
      }

      case FragmentType.SECTION_GROUP: {
        const sectionGroupType: SectionGroupType = SectionGroupType[json.sectionGroupType as string];
        result = new SectionGroupFragment(
          id,
          valueFrom(json),
          children as SectionFragment[],
          sectionGroupType,
          json.deleted
        );
        break;
      }

      default:
        throw new Error(`Failed to deserialise fragment JSON with type '${json.type}'.`);
    }

    // Set validity and weighting
    result.lastModifiedBy = UUID.orNull(json.lastModifiedBy);
    if (!isNaN(json.lastModifiedAt)) {
      result.lastModifiedAt = json.lastModifiedAt;
    }
    if (!isNaN(json.validFrom)) {
      result.validFrom = json.validFrom;
    }
    if (!isNaN(json.validTo)) {
      result.validTo = json.validTo;
    }
    if (!isNaN(json.weight)) {
      result.weight = json.weight;
    }

    // Patch up parent references
    result.parentId = UUID.orNull(json.parentId);
    result.children.forEach((child: Fragment) => {
      child.parent = result;
    });

    result.sectionId = UUID.orNull(json.sectionId);
    result.documentId = UUID.orNull(json.documentId);

    return result;
  }

  /**
   * Reconstruct a map from fragment ID to list of versions into their incremental
   * version trees, starting from the first tree with time >= startTime.  It is
   * assumed that the JSON version lists are already sorted by validFrom time.
   *
   * @param json      {any}          The JSON map of versions
   * @param startTime {number}       The time to exclude versions before
   * @param endTime   {number}       The time to exclude versions after, defaults to now
   * @returns         {Fragment[]}   An array of roots for the versioned trees
   */
  public static deserialiseAndUnbucket(json: any, startTime: number, endTime: number = Infinity): Fragment[] {
    // Auxillary data structures to aid in searching
    const fragments: Dictionary<Fragment[]> = {};
    const indexes: Dictionary<number> = {};
    const ids: string[] = Object.keys(json);
    for (const id of ids) {
      fragments[id] = json[id].map((j: any) => FragmentMapper.deserialise(j));
      indexes[id] = 0;
    }

    const versions: Fragment[] = [];
    let timestep: number = startTime;

    while (timestep < endTime + 1) {
      // Find the next item each bucket whose validTo time is has not passed.
      for (const id of ids) {
        const items: Fragment[] = fragments[id];
        let index: number = indexes[id];
        while (index < items.length - 1 && items[index].validTo !== null && items[index].validTo <= timestep) {
          ++index;
        }

        indexes[id] = index;
      }

      // Build the timeslice at the current timestep and unflatten it into a tree.
      // This contains a flattened representation of the tree at the current time.
      const timeslice: any[] = [];
      for (const id of ids) {
        const index: number = indexes[id];
        let parent: Fragment = fragments[id][index];
        let valid: boolean = !!parent && (null === parent.validTo || parent.validTo > timestep);

        // Recursively search up the implied tree to ensure either this fragment has no parent,
        // or has a valid parent.  If either of these fail, an ancestor should not be in the
        // tree at this timestep, so this fragment shouldn't be in the tree either.
        while (parent && valid) {
          const parentId: string = json[parent.id.value][indexes[parent.id.value]].parentId;
          valid = parent.isValid(timestep);
          parent = fragments[parentId] ? fragments[parentId][indexes[parentId]] : null;
        }

        if (valid) {
          timeslice.push(json[id][index]);
        }
      }

      // Find the next time following the current timestep, which may be a validFrom
      // or bounded validTo time strictly greater than timestep.
      let nextTime: number = Infinity;
      for (const id of ids) {
        const item: Fragment = fragments[id][indexes[id]];
        if (item.validFrom > timestep && item.validFrom < nextTime) {
          nextTime = item.validFrom;
        } else if (item.validTo !== null && item.validTo > timestep && item.validTo < nextTime) {
          const next: Fragment = fragments[id][indexes[id] + 1];
          if (next && next.validFrom === item.validTo) {
            nextTime = next.validFrom;
          } else {
            nextTime = item.validTo;
          }
        }
      }

      if (timeslice.length > 0) {
        const unflattened: Fragment[] = FragmentMapper.deserialiseAndUnflatten(timeslice);

        // Artificially set the lastModifiedAt time each fragment in this subtree, and attribute
        // the change to the user triggering this version change.
        for (const root of unflattened) {
          let latestChangeWithinWindow: number = 0;
          let lastModifyingUser: UUID = null;
          root.iterateDown(null, null, (iterator: Fragment) => {
            if (iterator.lastModifiedAt > latestChangeWithinWindow) {
              latestChangeWithinWindow = iterator.lastModifiedAt;
              lastModifyingUser = iterator.lastModifiedBy;
            }
          });
          root.iterateDown(null, null, (iterator: Fragment) => {
            iterator.lastModifiedAt = latestChangeWithinWindow;
            if (lastModifyingUser != null) {
              iterator.lastModifiedBy = lastModifyingUser;
            }
          });
        }

        versions.push(...unflattened);
      }

      // If we found no items bounded items with validFrom or validTo past the current
      // timestep, nextTime will equal Infinity still... and we drop out of the loop.
      timestep = nextTime;
    }

    return versions;
  }

  /**
   * Deserialise and reconstruct the fragment trees from an array of serialised
   * JSON objects.  Returns an array of root nodes in the set, which are identified
   * as those whose parent is not in the set.
   *
   * @param json {any[]}        The JSON objects
   * @returns    {Fragment[]}   The extracted tree roots
   */
  public static deserialiseAndUnflatten(json: any[]): Fragment[] {
    const byOwnId: Dictionary<Fragment> = {};
    const byParentId: Dictionary<Fragment[]> = {};

    // Deserialise all objects and index them by their ID and parentId for later.
    for (const item of json) {
      const fragment: Fragment = FragmentMapper.deserialise(item);
      byOwnId[item.id] = fragment;

      if (!byParentId[item.parentId]) {
        byParentId[item.parentId] = [];
      }
      byParentId[item.parentId].push(fragment);
    }

    // Add children to their parents.  If we didn't find a node with a given parentId,
    // that node must be a root; otherwise add the children to the parent.
    const roots: Fragment[] = [];
    const parentIds: string[] = Object.keys(byParentId);
    for (const id of parentIds) {
      const children: Fragment[] = byParentId[id];
      if (byOwnId[id]) {
        byOwnId[id].children.push(...children);
      } else {
        roots.push(...children);
      }
    }

    // Make sure all fragments have their children sorted by weight.
    for (const root of roots) {
      root.iterateDown(null, null, (iterator: Fragment) => iterator.sortChildren());
    }

    return roots;
  }

  /**
   * Clone a fragment into a new object reference.
   *
   * @param fragment {Fragment}   The fragment to clone
   * @returns        {Fragment}   The cloned object
   */
  public static clone(fragment: Fragment): Fragment {
    if (fragment instanceof Fragment) {
      const json: any = fragment.serialise();
      return FragmentMapper.deserialise(json);
    } else {
      return null;
    }
  }

  /**
   * Deep clone a fragment into a new object tree
   *
   * @param fragment {Fragment}   The fragment to deep-clone
   * @returns        {Fragment}   The deep-cloned object
   */
  public static deepClone(fragment: Fragment): Fragment {
    if (fragment instanceof Fragment) {
      const json: any = this._serialiseWithDescendants(fragment);
      return FragmentMapper.deserialise(json);
    } else {
      return null;
    }
  }

  /**
   * Private static helper to recursively serialise a fragment and its descendents.
   *
   * @param fragment {Fragment}   The fragment to serialise
   * @returns        {any}        The serialised fragment
   */
  private static _serialiseWithDescendants(fragment: Fragment): any {
    const json: any = fragment.serialise();
    json['children'] = fragment.children.map((child) => this._serialiseWithDescendants(child));
    return json;
  }

  /**
   * Static helper to create a new textual fragment from a given type.
   * Defaults to a text fragment.
   *
   * @param type  {FragmentType} Type of fragment to create
   * @param value {string}       Value of fragment
   * @returns     {Fragment}     The new textual fragment
   */
  public static createTextualFrom(type: FragmentType, value: string = ''): Fragment {
    let fragment: Fragment;

    switch (type) {
      case FragmentType.SUPERSCRIPT:
        {
          fragment = new SuperscriptFragment(null, value);
        }
        break;

      case FragmentType.SUBSCRIPT:
        {
          fragment = new SubscriptFragment(null, value);
        }
        break;

      case FragmentType.MEMO:
        {
          fragment = new MemoFragment(null, value);
        }
        break;

      case FragmentType.TEXT:
      default: {
        fragment = new TextFragment(null, value);
      }
    }

    return fragment;
  }
}

/**
 * Helper function to deserialise an array of JSON data to Fragments.
 *
 * @param json {any[]}        The JSON to deserialise
 * @returns    {Fragment[]}   The deserialised classes
 */
function deserialiseArray(json: any[]): Fragment[] {
  json = typeof json === 'string' ? JSON.parse(json) : json;
  json = json instanceof Array ? json : [json];
  const result: Fragment[] = [];

  for (let i: number = 0; i < json.length; ++i) {
    result.push(FragmentMapper.deserialise(json[i]));
  }

  return result;
}

/**
 * Helper function to extract the value from a JSON object.
 *
 * @param json {any}      The JSON to extract from
 * @returns    {string}   The value
 */
function valueFrom(json: any): string {
  return json && typeof json.value === 'string' ? json.value : void 0;
}
