import {UUID} from 'app/utils/uuid';
import {Fragment, FragmentType} from '../types';
import {AssociatedDocumentType} from './associated-document-information-type';

export class AssociatedDocumentInformationFragment extends Fragment {
  public associatedDocumentType: AssociatedDocumentType;
  public filename: String;
  public reference: String;

  constructor(id: UUID, associatedDocumentType: AssociatedDocumentType, filename: String, reference: String) {
    super(id, FragmentType.ASSOCIATED_DOCUMENT_INFORMATION, []);
    this.associatedDocumentType = associatedDocumentType;
    this.filename = filename;
    this.reference = reference;
  }

  /**
   * @inheritdoc
   */
  public split(offset: number): AssociatedDocumentInformationFragment {
    return null;
  }
}
