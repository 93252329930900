import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AdministrationUtils} from 'app/documents/administration-utils';
import {Logger} from 'app/error-handling/services/logger/logger.service';
import {suiteToIndexer} from 'app/fragment/indexing/fragment-index.service';
import {DocumentFragment, Fragment, FragmentType, SectionFragment, SectionType} from 'app/fragment/types';
import {SectionGroupFragment} from 'app/fragment/types/section-group-fragment';
import {DocumentsSearchType} from 'app/search/document-selector/documents-search-types';
import {SearchableDocument, SearchableDocumentColumn} from 'app/search/search-documents.service';
import {CloningService} from 'app/services/cloning.service';
import {DocumentService} from 'app/services/document.service';
import {TdifSection} from 'app/tdif/tdif-types';
import {UUID} from 'app/utils/uuid';

export enum CloneStatus {
  NONE,
  IN_PROGRESS,
  FAIL,
  SUCCESS,
}

@Component({
  selector: 'cars-section-clone',
  templateUrl: './section-clone.component.html',
  styleUrls: ['./section-clone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionCloneComponent implements OnInit {
  public readonly DocumentsSearchType: typeof DocumentsSearchType = DocumentsSearchType;
  public readonly searchPlaceholder: string = 'Select a document';

  private _targetDocumentId: UUID;
  private _sourceDocumentId: UUID;
  private _cloneStatus: CloneStatus;
  private _indexLookup: Record<string, string>;

  public sections: TdifSection[];
  public selectedSourceDocument: SearchableDocument;
  public selectedSourceSections: TdifSection[];
  public loadingSections: boolean;
  public cloning: boolean;
  public selectedSection: TdifSection;
  public cloneDiscussions: boolean;

  public static open(dialog: MatDialog): MatDialogRef<SectionCloneComponent> {
    return dialog.open(SectionCloneComponent, {
      ariaLabel: 'Clone section dialog',
      width: '90%',
      maxWidth: '700px',
      disableClose: false,
    });
  }

  constructor(
    private _dialogRef: MatDialogRef<SectionCloneComponent>,
    private _documentService: DocumentService,
    private _cloningService: CloningService,
    private _snackBar: MatSnackBar,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._targetDocumentId = this._documentService.getSelected().id;
    this._sourceDocumentId = null;
    this.selectedSourceSections = [];
    this._cloneStatus = CloneStatus.NONE;
    this.loadingSections = false;
    this.cloning = false;
    this.cloneDiscussions = true;
  }

  public getSectionsFromSelectedSourceDocument(selectedDocument: SearchableDocument): void {
    this.loadingSections = true;
    this.selectedSourceDocument = selectedDocument;
    this._sourceDocumentId = selectedDocument[SearchableDocumentColumn.DOCUMENT_ID];

    this._documentService.load(this._sourceDocumentId, {projection: 'ROOT_AND_CHILDREN'}).then((document) => {
      this._indexLookup = suiteToIndexer[document.suite](document);
      this.sections = document.children
        .filter(this._filterEligibleSectionsToClone.bind(this))
        .map(this._mapEligibleSectionsToClone.bind(this));
      this.loadingSections = false;
      this._cloneStatus = CloneStatus.NONE;
      this._cdr.markForCheck();
    });
  }

  private _filterEligibleSectionsToClone(fragment: Fragment): boolean {
    if (fragment.is(FragmentType.SECTION_GROUP)) {
      return !(fragment as SectionGroupFragment).deleted;
    }
    if ((fragment as SectionFragment).deleted) {
      return false;
    }

    return (fragment as SectionFragment).isSectionOfType(
      SectionType.APPENDIX,
      SectionType.INTRODUCTORY,
      SectionType.NORMATIVE
    );
  }

  private _mapEligibleSectionsToClone(fragment: Fragment): TdifSection {
    let title: string;
    let sectionType: SectionType;
    if (fragment.is(FragmentType.SECTION_GROUP)) {
      title =
        'Nationally determined section: ' +
        fragment.children
          .map(
            (ndsChild) =>
              `${AdministrationUtils.getInitials((ndsChild as SectionFragment).administration)} - ${ndsChild.value}`
          )
          .reduce((returnString, currentString) => returnString + ', ' + currentString);
      sectionType = null;
    } else {
      title = fragment.value;
      sectionType = (fragment as SectionFragment).sectionType;
    }
    const index = this._indexLookup[fragment.id.value];
    return new TdifSection(fragment.id, sectionType, index, title, []);
  }

  private _getIndexedChildren(document: DocumentFragment): Fragment[] {
    return document.children.filter((documentChild) => {
      if (documentChild.is(FragmentType.SECTION_GROUP)) {
        return !(documentChild as SectionGroupFragment).deleted;
      }

      const section: SectionFragment = documentChild as SectionFragment;

      return (
        !section.deleted &&
        section.isSectionOfType(SectionType.NORMATIVE, SectionType.REFERENCE_NORM, SectionType.REFERENCE_INFORM)
      );
    });
  }

  public handleSelectSection(selectedSections: TdifSection[]): void {
    this.selectedSourceSections = selectedSections;
    this._cloneStatus = CloneStatus.NONE;
  }

  public clone() {
    this.cloning = true;
    this._cloneStatus = CloneStatus.IN_PROGRESS;
    this._dialogRef.disableClose = true;

    this._cloningService
      .cloneSections(
        this._sourceDocumentId,
        this._targetDocumentId,
        this.selectedSourceSections.map((section) => section.id),
        this.cloneDiscussions
      )
      .subscribe({
        next: (_response: string) => {
          this._cloneStatus = CloneStatus.SUCCESS;
          this.cloning = false;
          this.selectedSourceSections = [];
          this._dialogRef.disableClose = false;
          this._cdr.markForCheck();
        },
        error: (err) => {
          Logger.error('clone-error', 'Failed to clone section', err.error);
          this._cloneStatus = CloneStatus.FAIL;
          this.cloning = false;
          this._dialogRef.disableClose = false;
          this._cdr.markForCheck();
        },
      });
  }

  private _onLoadFailure(message: string, err?: any): void {
    this.loadingSections = false;
    Logger.error('internal-reference-error', message, err);
    this._snackBar.open(message, 'Dismiss');
  }

  public disableCloneButton(): boolean {
    return this.selectedSourceSections.length === 0 || !this._sourceDocumentId || this.cloning;
  }

  public closeCancel() {
    this._dialogRef.close();
  }

  public showDisplayPopupText(): boolean {
    return this._cloneStatus !== null && this._cloneStatus !== CloneStatus.NONE;
  }

  public disableDocumentSelector(): boolean {
    return this._disableSelectors();
  }

  public disableSectionSelector(): boolean {
    return this._disableSelectors() || !this.sections?.length;
  }

  private _disableSelectors(): boolean {
    return this._cloneStatus === CloneStatus.IN_PROGRESS;
  }

  public popupTextToDisplay(): string {
    switch (this._cloneStatus) {
      case CloneStatus.IN_PROGRESS:
        return 'Cloning in progress. Please wait...';
      case CloneStatus.SUCCESS:
        return (
          'Successfully cloned section. You may now clone another section or close this modal to review your document. Clause references may appear broken,' +
          ' so please use the "Refresh references" button to resolve such issues. Existing WSR mappings for the cloned sections were removed, so will need repopulating.'
        );
      case CloneStatus.FAIL:
        return 'Failed to clone section. Please try again. If this issue persists, please contact support using the button provided at the top of the screen';
      default:
        return '';
    }
  }

  public popupTextClass(): string {
    switch (this._cloneStatus) {
      case CloneStatus.IN_PROGRESS:
        return 'clone-progress';
      case CloneStatus.SUCCESS:
        return 'clone-success';
      case CloneStatus.FAIL:
        return 'clone-failure';
      default:
        return '';
    }
  }
}
